import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import awsExports from "./aws-exports"; // Make sure this is correctly configured
import Admin from "./Admin/Admin";
import Home from "./_Pages/Home/Home";
import { listPages } from "./graphql/queries";
import "./App.scss";
import TopNav from "./Components/TopNav/TopNav";
import Footer from "./Components/Footer/Footer";
import Contact from "./_Pages/Contact/Contact";
import AddPage from "./Admin/AddPage";
import EditPage from "./Admin/EditPage";
import Blog from "./_Pages/Blog/Blog";
import Analytics from "./_Pages/Analytics/Analytics";
import ReactGA from "react-ga4"; // Google Analytics
// import PageRenderer from "./_Utils/PageRenderer";

Amplify.configure(awsExports); // Configure Amplify

// Component to handle Google Analytics tracking
function GoogleAnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    const trackingId = "G-56PWVBDZ22"; // Replace with your Google Analytics tracking ID
    ReactGA.initialize(trackingId);
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null; // This component doesn't render anything
}

const App = () => {
  const [pages, setPages] = useState([]);
  const client = generateClient();

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const apiData = await client.graphql({ query: listPages });
      setPages(apiData.data.listPages.items);
    } catch (err) {
      console.error("Error fetching pages:", err);
    }
  };

  return (
    <div className="app">
      {/* <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/my-admin" element={<Admin />} />

          <Route path="/:pageUrl" component={PageRenderer} />
        </Routes>
      </Router> */}
      <Router>
        <GoogleAnalyticsTracker />
        <TopNav />
        <Routes>
          {/* Static routes like your home or admin page */}
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/my-admin" element={<Admin />} />
          <Route path="/my-admin/analytics" element={<Analytics />} />
          <Route path="/my-admin/add-page" element={<AddPage />} />
          <Route path="/my-admin/edit-page/:id" element={<EditPage />} />
          {/* Dynamic routes */}
          {pages.map((page) => (
            <Route
              key={page.id}
              path={`/${page.url}`}
              element={
                <DynamicPage content={page.content} title={page.title} />
              }
            />
          ))}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

const DynamicPage = ({ content, title }) => {
  return (
    <div className="blog-page">
      <div className="container ql-editor">
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </div>
  );
};

export default App;
