import "./Contact.scss";
// import ContactForm from "../../Components/Forms/Contact/Contact";
import BrevoContact from "../../Components/Forms/Brevo/BrevoContact";

const Contact = () => {
  return (
    <div className="contact">
      {/* <div className="container">
        <h1>Contact Us</h1> */}
      {/* <ContactForm /> */}
      <BrevoContact />
      {/* </div> */}
    </div>
  );
};

export default Contact;
